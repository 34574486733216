// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { ready } from 'jquery'
const endpoint = 'agenda'

export const fetchEvents = createAsyncThunk('agenda/fetchEvents', async params => {
  const response = await axios.get(endpoint, { params })
  const responseTarefas = await axios.get('agenda_tarefa', { params })
  return {
    contratos: response.data.data,
    tarefas: responseTarefas.data.data,
    all: response.data.data.concat(responseTarefas.data.data)
  }
})

export const getAllEvents = createAsyncThunk('agenda/getAllEvents', async (params, { rejectWithValue }) => {

  try {
    const response = await axios.get('agenda_calendario', { params });

    return {
      calendar: response.data.data,
      paramsCalendar: {
        startStr: params.startStr,
        endStr: params.endStr
      },
    };
  } catch (error) {
    if (!error.response) {
      throw err
    }
    return rejectWithValue(error.response?.data);
  }
});

export const getAllEventsBlock = createAsyncThunk('agenda/getAllEventsBlock', async (params, { rejectWithValue }) => {

  try {
    const response = await axios.get('/agenda_bloqueios', { params })

    return {
      blocker: response.data,
      paramsCalendar: {
        month: params.month,
        year: params.year
      },
    };
  } catch (error) {
    return rejectWithValue(error.response?.data);
  }
});

export const fetchBlockedDates = createAsyncThunk('/agenda_bloqueios', async params => {
  const response = await axios.get('/agenda_bloqueios', { params })
  return response.data.data
})

export const fetchBlockedVariousDates = createAsyncThunk('/agenda-bloqueios/bulk-update', async (blocks) => {
  const response = await axios.put('/agenda-bloqueios/bulk-update', { blocks })
  return response.data.data
})

export const fetchTipos = createAsyncThunk('agenda/fetchTipos', async calendars => {
  const response = await axios.get('contrato_tipo')
  return response.data.data
})

export const fetchUnidades = createAsyncThunk('agenda/fetchUnidades', async calendars => {
  const response = await axios.get('business_units', { params: { forSelect: true } })
  return response.data.data
})

export const addEvent = createAsyncThunk('agenda/addEvent', async (event, { dispatch, getState }) => {
  const response = await axios.post('orcamentoAgenda', event)
  return response
})

export const updateEvent = createAsyncThunk('appCalendar/updateEvent', async (event, { dispatch, getState }) => {
  await axios.post('/apps/calendar/update-event', { event })
  await dispatch(fetchEvents(getState().calendar.selectedCalendars))
  return event
})


export const updateAllFilters = createAsyncThunk('appCalendar/updateAllFilters', async (value, { dispatch, getState }) => {
  if (value === true) {
    await dispatch(fetchEvents())
  } else {
    await dispatch(fetchEvents(getState().calendar.selectedCalendars))
  }
  return value
})

export const removeEvent = createAsyncThunk('appCalendar/removeEvent', async id => {
  await axios.delete('/apps/calendar/remove-event', { id })
  return id
})

export const AgendaSlice = createSlice({
  name: 'agenda',
  initialState: {
    status: 'READY',
    events: [],
    tipos: [],
    contratos: [],
    info: {},
    infoBlock: {},
    tarefas: [],
    eventsCalendar: [],
    unidades: [],
    selectedEvent: {},
    selectedCalendars: [],
    message: ''
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.events = action.payload.all
        state.tarefas = action.payload.tarefas
        state.contratos = action.payload.contratos
      })
      .addCase(fetchTipos.fulfilled, (state, action) => {
        state.tipos = action.payload
      })
      .addCase(fetchUnidades.fulfilled, (state, action) => {
        state.unidades = action.payload
      })
      .addCase(addEvent.fulfilled, (state, action) => {
        state.status = 'SAVE_SUCCESS'
        state.message = action.payload.message
      })
      .addCase(updateAllFilters.fulfilled, (state, action) => {
        const value = action.payload
        let selected = []
        if (value === true) {
          selected = state.tipos
        } else {
          selected = []
        }
        state.selectedCalendars = selected
      })
      .addCase(fetchBlockedVariousDates.fulfilled, (state, action) => {
        state.status = 'SAVE_SUCCESS'
      })
      .addCase(fetchBlockedVariousDates.pending, (state, action) => {
        state.status = 'PENDING'
      })
      .addCase(fetchBlockedVariousDates.rejected, (state, action) => {
        state.status = 'ERROR'
        state.message = action.payload.message || 'Não foi possível bloquear'
      })
      .addCase(getAllEvents.fulfilled, (state, action) => {
        state.eventsCalendar = action.payload.calendar
        state.info = action.payload.paramsCalendar
        state.status = 'SUCCESS'
      })
      .addCase(getAllEvents.pending, (state, action) => {
        state.status = 'PENDING'
      })
      .addCase(getAllEvents.rejected, (state, action) => {
        state.status = 'ERROR'
        state.message = action?.payload?.message
      })
      .addCase(getAllEventsBlock.fulfilled, (state, action) => {
        state.eventBlocked = action.payload.blocker
        state.infoBlock = action.payload.paramsCalendar
        state.status = 'SUCCESS_BLOCK'
      })
      .addCase(getAllEventsBlock.pending, (state, action) => {
        state.status = 'PENDING_BLOCK'
      })
      .addCase(getAllEventsBlock.rejected, (state, action) => {
        state.status = 'ERROR_BLOCK'
        state.message = action?.payload?.message
      })
  }
})

export const { selectEvent } = AgendaSlice.actions

export default AgendaSlice.reducer