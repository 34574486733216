import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const endpoint = 'admin/organizacoes'

export const getAll = createAsyncThunk(`${endpoint}/all`, async params => {

    const response = await axios.get(`${endpoint}`, { params })

    return {
        data: response.data.data,
        total: response.data.meta?.total,
        last_page: response.data.meta?.last_page,
        params
    }
})

export const getOrganization = createAsyncThunk(`${endpoint}/getOrganization`, async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${endpoint}/${id}`, { id })
        return response.data
      } catch (err) {
        if (!err.response) {
          throw err
        }
        return rejectWithValue(err.response.data.error)
      }
})

export const get = createAsyncThunk(`${endpoint}/get`, async (id, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${endpoint}/auth/${id}`, { id })
        return response.data
      } catch (err) {
        if (!err.response) {
          throw err
        }
        return rejectWithValue(err.response.data.error)
      }
})

export const organizationsSlice = createSlice({
    name: 'organizations',
    initialState: {
        data: [],
        status: 'READY',
        total: 0,
        params: {},
        selected: {},
        error: ''
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getAll.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.total
                state.last_page = action.payload.last_page
                state.status = 'SUCCESS'
            })
            .addCase(getAll.pending, (state) => {
                state.status = 'PENDING'
            })
            .addCase(getAll.rejected, (state, action) => {
                state.data = []
                state.error = action.payload || 'Não foi possível carregar os dados'
                state.status = 'ERROR'
            })
            .addCase(get.pending, (state, action) => {
                state.status = 'GET_PENDING'
                state.selected = action.payload
            })
            .addCase(get.rejected, (state, action) => {
                state.status = 'GET_ERROR'
                state.error = action.payload?.message
            })
            .addCase(get.fulfilled, (state, action) => {
                state.status = 'GET_SUCCESS'
                state.selected = action.payload
            })
            .addCase(getOrganization.pending, (state, action) => {
                state.status = 'GET_ORG_PENDING'
            })
            .addCase(getOrganization.rejected, (state, action) => {
                state.status = 'GET_ORG_ERROR'
                state.error = action.payload?.message || "Erro ao carregar a organização"
            })
            .addCase(getOrganization.fulfilled, (state, action) => {
                state.status = 'GET_ORG_SUCCESS'
                state.selected = action.payload
            })
    }
})

export default organizationsSlice.reducer
