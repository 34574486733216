import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
const endpoint = 'expense_sub_categories'

export const getAll = createAsyncThunk(`${endpoint}`, async (params, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${endpoint}`, { params })
        return response.data
    } catch (error) {
        if (!error.response) {
            throw err
        }
        return rejectWithValue(error.response?.data);
    }
})

export const get = createAsyncThunk(`${endpoint}/get`, async (id) => {
    const response = await axios.get(`${endpoint}/${id}`, { id })
    return response.data
})

export const add = createAsyncThunk(`${endpoint}/add`, async (item, { rejectWithValue }) => {

    try {
        const response = await axios.post(`${endpoint}`, item)
        return response.data.data
    } catch (e) {
        if (!e.response) {
            throw e
        }
        return rejectWithValue(e.response.data)
    }
})

export const update = createAsyncThunk(`${endpoint}/update`, async (item) => {
    const response = await axios.put(`${endpoint}/${item.id}`, item)
    return response.data
})

export const archive = createAsyncThunk(`${endpoint}/archive`, async (item, { dispatch }) => {
    await axios.put(`${endpoint}/${item.id}`, item)
    return item
})

export const unarchive = createAsyncThunk(`${endpoint}/unarchive`, async (item, { dispatch }) => {
    await axios.put(`${endpoint}/${item.id}`, item)
    return item
})

export const subcategorieSlice = createSlice({
    name: 'expense_sub_categories',
    initialState: {
        data: [],
        status: 'READY',
        total: 0,
        params: {},
        selected: {},
        error: ''
    },
    reducers: {
        clearSelected: (state, payload) => {
            state.selected = {}
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getAll.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.status = 'SUCCESS'
            })
            .addCase(getAll.pending, (state) => {
                state.data = []
                state.status = 'PENDING'
            })
            .addCase(getAll.rejected, (state, action) => {
                state.data = []
                state.error = action.payload || 'Não foi possível carregar os dados'
                state.status = 'ERROR'
            })
            .addCase(get.pending, (state, action) => {
                state.status = 'PENDING'
                state.selected = action.payload
            })
            .addCase(get.rejected, (state, action) => {
                state.status = 'ERROR'
                state.error = action.payload
            })
            .addCase(get.fulfilled, (state, action) => {
                state.status = 'SUCCESS'
                state.selected = action.payload
            })
            .addCase(add.fulfilled, (state, action) => {
                state.status = 'SAVE_SUBCATEGORY_SUCCESS'
                state.message = 'Salvo com Sucesso!'
            })
            .addCase(add.rejected, (state, action) => {
                state.status = 'SAVE_SUBCATEGORY_ERROR'
                state.message = action.payload.message || 'Não foi possível salvar'
            })
            .addCase(update.fulfilled, (state, action) => {
                state.status = 'UPDATED_SUBCATEGORY_SUCCESS'
                state.message = 'Atualizado com Sucesso!'
                state.selected = action.payload
            })
            .addCase(update.pending, (state) => {
                state.status = 'PENDING'
            })
            .addCase(update.rejected, (state, action) => {
                state.status = 'UPDATED_SUBCATEGORY_ERROR'
                state.message = action.payload.message || 'Não foi possível atualizar'
            })
            .addCase(archive.fulfilled, (state, action) => {
                state.status = 'ARCHIVED_SUBCATEGORY_SUCCESS'
            })
            .addCase(archive.rejected, (state, action) => {
                state.selected = action.error
                state.status = 'ARCHIVED_SUBCATEGORY_ERROR'
            })
            .addCase(unarchive.fulfilled, (state, action) => {
                state.status = 'UNARCHIVED_SUBCATEGORY_SUCCESS'
            })
            .addCase(unarchive.rejected, (state, action) => {
                state.selected = action.error
                state.status = 'UNARCHIVED_SUBCATEGORY_ERROR'
            })
    }
})

export const { clearSelected } = subcategorieSlice.actions
export default subcategorieSlice.reducer 