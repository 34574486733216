import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
const endpoint = 'option'

export const getAll = createAsyncThunk(`${endpoint}/all`, async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${endpoint}`, { params })
    return {
      data: response.data,
      total: response.data.lenght,
      params
    }
  } catch (error) {
    if (!error.response) {
      throw err
    }
    return rejectWithValue(error.response?.data);
  }
})

export const get = createAsyncThunk(`${endpoint}/get`, async params => {
  const response = await axios.get(`${endpoint}/${params.id}`, { params })
  return response.data
})

export const add = createAsyncThunk(`${endpoint}/add`, async (item, { dispatch, rejectWithValue }) => {


  try {
    const response = await axios.post(endpoint, item)

    return response.data
  } catch (e) {

    if (!e.response) {
      throw e
    }

    return rejectWithValue(e.response.data)
  }

  return item
})

export const update = createAsyncThunk(`${endpoint}/update`, async (item, { dispatch }) => {
  await axios.put(`${endpoint}/${item.id}`, item)
  return item
})

export const uploadFile = createAsyncThunk('/uploadFile', async ({ id, file }) => {
  const formData = new FormData();
  formData.append('upload_arquivo', file);
  formData.append('_method', 'PUT');

  try {
    const response = await axios.post(`/option/${id}`, formData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Erro ao fazer upload do arquivo');
  }
}
);

export const downloadFile = createAsyncThunk(`${endpoint}/downloadFile`, async (item) => {
  const response = await axios.get(`${endpoint}/downloadpdf/${item.id}`, { responseType: 'blob' })
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', item.file_name);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
})

export const remove = createAsyncThunk(`${endpoint}/remove`, async (id, { dispatch }) => {
  await axios.delete(`${endpoint}/${id}`)
  await dispatch(getAll())
})

export const removeMultiples = createAsyncThunk(`${endpoint}/removeMultiples`, async (ids, { dispatch }) => {
  await axios.delete(`${endpoint}/delete-multiples`, { data: { ids } })
  await dispatch(getAll())
})

export const optionSlice = createSlice({
  name: endpoint,
  initialState: {
    data: [],
    message: '',
    status: 'READY',
    selected: {},
    total: 0,
    params: {},
    error: ''
  },
  reducers: {
    clearSelected: (state, payload) => {
      state.selected = {}
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
        state.status = 'SUCCESS'
      })
      .addCase(getAll.pending, (state) => {
        state.loading = true
        state.data = []
        state.status = 'PENDING'
      })
      .addCase(getAll.rejected, (state, action) => {
        state.loading = false
        state.data = []
        state.error = action.payload || 'Não foi possível carregar os dados'
        state.status = 'ERROR'
      })
      .addCase(get.pending, (state, action) => {
        state.status = 'PENDING'
        state.selected = action.payload
      })
      .addCase(get.rejected, (state, action) => {
        state.status = 'ERROR'
        state.error = action.payload
      })
      .addCase(get.fulfilled, (state, action) => {
        state.status = 'SUCCESS'
        state.selected = action.payload
      })
      .addCase(add.fulfilled, (state, action) => {
        state.status = 'SAVE_SUCCESS'
        state.message = 'Mensagem cadastrada com sucesso!'
      })
      .addCase(add.rejected, (state, action) => {
        state.status = 'SAVE_ERROR'
        state.message = action.payload.message || 'Não foi possível salvar'
        state.loading = false
      })
      .addCase(update.fulfilled, (state, action) => {
        state.status = 'UPDATED_SUCCESS'
        state.selected = action.payload
        state.message = 'Mensagem atualizada com sucesso!'
      })
      .addCase(update.rejected, (state, action) => {
        state.status = 'UPDATED_ERROR'
        state.message = 'Não foi possível salvar'
        state.loading = false
      })
      .addCase(remove.rejected, (state, action) => {
        state.status = 'REMOVE_ERROR'
      })
      .addCase(remove.fulfilled, (state, action) => {
        state.status = 'REMOVE_SUCCESS'
      })
      .addCase(remove.pending, (state, action) => {
        state.status = 'REMOVE_PENDING'
      })
      .addCase(removeMultiples.rejected, (state, action) => {
        state.status = 'REMOVE_ERROR'
      })
      .addCase(removeMultiples.fulfilled, (state, action) => {
        state.status = 'REMOVE_MULTIPLES_SUCCESS'
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.message = action?.payload?.message
        state.status = 'UPLOAD_SUCCESS'
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.status = 'UPLOAD_ERROR'
        state.message = action?.payload?.message || 'Não foi possível fazer o upload'
      })
      .addCase(uploadFile.pending, (state, action) => {
        state.status = 'UPLOAD_PENDING'
      })
  }
})

export const { clearSelected } = optionSlice.actions
export default optionSlice.reducer