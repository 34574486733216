import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const landingConfigs = createAsyncThunk(`lp/nick`, async nick => {
  const response = await axios.get(`lp/${nick}`)
  return response.data
})

export const newLead = createAsyncThunk(`lp/newLead`, async data => {
  const response = await axios.post(`lp/newLead`, data)
  return response.data
})


export const publicConfigsSlice = createSlice({
  name: 'publicConfigs',
  initialState: {
    configs: {},
    lead: {},
    status: 'READY',
    message: ''
  },
  reducers: {
    clearSelected: (state, payload) => {
      state.configs = {}
    }
  },
  extraReducers: builder => {
    builder
        .addCase(landingConfigs.fulfilled, (state, action) => {
          state.configs = action.payload
          state.status = 'SUCCESS'
        })
        .addCase(landingConfigs.pending, (state, action) => {
          state.configs = {}
          state.status = 'PENDING'
        })
        .addCase(newLead.fulfilled, (state, action) => {
          state.lead = action.payload
          state.status = 'SAVE_SUCCESS'
        })
        .addCase(newLead.pending, (state, action) => {
          state.lead = {}
          state.status = 'SAVING'
        })
        .addCase(newLead.rejected, (state, action) => {
          state.lead = {}
          state.status = 'SAVE_ERROR'
          state.message = action.payload.message
        })
  }
})

export const { clearSelected } = publicConfigsSlice.actions
export default publicConfigsSlice.reducer
