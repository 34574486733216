import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { authSlice } from "../../../redux/authentication"

const endpoint = 'dependentes'

axios.defaults.withCredentials = true
export const getAll = createAsyncThunk(`${endpoint}/all`, async (params, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${endpoint}`, { params })
        return {
            data: response.data.data,
            total: response.data.meta?.total,
            params
        }
    } catch (error) {
        if (!error.response) {
            throw err
        }
        return rejectWithValue(error.response?.data);
    }
})

export const getAllDependents = createAsyncThunk(`usuario-dependentes`, async params => {
    const response = await axios.get('usuario-dependentes', { params })
    return response.data
})

export const get = createAsyncThunk(`${endpoint}/get`, async id => {
    const response = await axios.get(`${endpoint}/${id}`, { id })
    return response.data
})

export const add = createAsyncThunk(`${endpoint}/add`, async (item, { dispatch, rejectWithValue }) => {

    const response = await axios.post(endpoint, item)
    return response.data.data
})

export const update = createAsyncThunk(`${endpoint}/update`, async (item, { dispatch }) => {

    const response = await axios.put(`${endpoint}/${item.id}`, item)
    return response.data
})

export const remove = createAsyncThunk(`${endpoint}/remove`, async (id, { dispatch }) => {
    await axios.delete(`${endpoint}/${id}`)
})

export const getDependenteCliente = createAsyncThunk(`${endpoint}/dependentesDoCliente/`, async idcliente => {
    const response = await axios.get(`${endpoint}/dependentesDoCliente/${idcliente}`, { idcliente })
    return {
        data: response.data,
        total: response.data.length
    }
})

export const dependentesSlice = createSlice({
    name: 'dependentes',
    initialState: {
        data: [],
        message: '',
        status: 'READY',
        total: 0,
        params: {},
        error: '',
        editOpen: false,
        deleteOpen: false,
        selected: {},
        item: {}
    },
    reducers: {
        toggleEdit: (state, action) => {
            state.editOpen = !state.editOpen
        },
        toggleDelete: (state, action) => {
            state.deleteOpen = !state.deleteOpen
        },
        setSelected: (state, action) => {
            state.selected = action.payload
        },
        setStatus: (state, action) => {
            state.status = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getAll.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.total
                state.status = 'SUCCESS'
                state.loading = false
            })
            .addCase(getAll.pending, (state) => {
                state.loading = true
                state.data = []
                state.status = 'PENDING'
            })
            .addCase(getAll.rejected, (state, action) => {
                state.loading = false
                state.data = []
                state.error = action.payload || 'Não foi possível carregar os dados'
                state.status = 'ERROR'
            })
            .addCase(getAllDependents.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.total
                state.last_page = action.payload.last_page
                state.status = 'SUCCESS'
            })
            .addCase(getAllDependents.pending, (state) => {
                state.loading = true
                state.data = []
                state.status = 'PENDING'
            })
            .addCase(getAllDependents.rejected, (state, action) => {
                state.loading = false
                state.data = []
                state.error = action.payload || 'Não foi possível carregar os dados'
                state.status = 'ERROR'
            })
            .addCase(getDependenteCliente.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.total
                state.status = 'SUCCESS'
                state.loading = false
            })
            .addCase(getDependenteCliente.pending, (state) => {
                state.loading = true
                state.data = []
                state.status = 'PENDING'
            })
            .addCase(getDependenteCliente.rejected, (state, action) => {
                state.loading = false
                state.data = []
                state.error = action.payload || 'Não foi possível carregar os dados'
                state.status = 'ERROR'
            })
            .addCase(get.pending, (state, action) => {
                state.status = 'PENDING'
                state.selected = action.payload
                state.loading = true
            })
            .addCase(get.rejected, (state, action) => {
                state.status = 'ERROR'
                state.error = action.payload
                state.loading = false
            })
            .addCase(get.fulfilled, (state, action) => {
                state.status = 'SUCCESS'
                state.selected = action.payload
                state.loading = false
            })
            .addCase(add.fulfilled, (state, action) => {
                state.status = 'SAVE_SUCCESS'
                state.item = action.payload
                state.message = action.payload.message
                state.editOpen = false
            })
            .addCase(add.rejected, (state, action) => {
                state.status = 'SAVE_ERROR'
                state.message = action.payload.message || 'Não foi possível salvar'
                state.loading = false
            })
            .addCase(update.fulfilled, (state, action) => {
                state.status = 'SAVE_SUCCESS'
                state.message = action.payload.message
                state.editOpen = false
            })
            .addCase(update.rejected, (state, action) => {
                state.status = 'SAVE_ERROR'
                state.message = action.payload?.message || 'Não foi possível salvar'
                state.loading = false
            })
            .addCase(remove.rejected, (state, action) => {
                state.status = 'REMOVE_ERROR'

            })
            .addCase(remove.fulfilled, (state, action) => {
                state.status = 'REMOVE_SUCCESS'

            })
            .addCase(remove.pending, (state, action) => {
                state.status = 'REMOVE_PENDING'

            })


    }
})

export const { toggleEdit, toggleDelete, setSelected, setStatus } = dependentesSlice.actions
export default dependentesSlice.reducer
