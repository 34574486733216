import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
const endpoint = 'faturas'

export const getAll = createAsyncThunk(`${endpoint}/all`, async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${endpoint}`, { params })
    return {
      data: response.data.data,
      total: response.data.meta.total,
      pages: response.data.meta.last_page,
      report: response.data.report,
      params
    }
  } catch (error) {
    if (!error.response) {
      throw err
    }
    return rejectWithValue(error.response?.data);
  }
})

export const get = createAsyncThunk(`${endpoint}/get`, async id => {
  const response = await axios.get(`${endpoint}/${id}`, { id })
  return response.data
})

export const add = createAsyncThunk(`${endpoint}/add`, async (item, { dispatch, rejectWithValue }) => {


  try {
    const response = await axios.post(endpoint, item)

    return response.data
  } catch (e) {

    if (!e.response) {
      throw e
    }

    return rejectWithValue(e.response.data)
  }

  return item
})

export const update = createAsyncThunk(`${endpoint}/update`, async (item, { dispatch }) => {
  await axios.put(`${endpoint}/${item.id}`, item)
  return item
})

export const remove = createAsyncThunk(`${endpoint}/remove`, async (id, { dispatch }) => {
  await axios.delete(`${endpoint}/${id}`)
})

export const removeMultiples = createAsyncThunk(`${endpoint}/removeMultiples`, async (ids, { dispatch }) => {
  await axios.delete(`${endpoint}/delete-multiples`, { data: { ids } })
})

export const invoicesSlice = createSlice({
  name: endpoint,
  initialState: {
    data: [],
    message: '',
    status: 'READY',
    total: 0,
    params: {},
    error: '',
    pages: 1,
    report: {
      total: 0,
      totalAmount: 0,
      totalPaid: 0,
      totalPaidValue: 0,
      totalDue: 0,
      totalDueValue: 0,
      totalPending: 0,
      totalPendingValue: 0
    },
    paymentMethods: [
      { label: 'Boleto', value: 'Boleto' },
      { label: 'PIX', value: 'PIX' },
      { label: 'Cartão de Crédito', value: 'Cartão de Crédito' },
      { label: 'Cartão de Débito', value: 'Cartão de Débito' },
      { label: 'Cheque', value: 'Cheque' },
      { label: 'Dinheiro', value: 'Dinheiro' },
      { label: 'Transferência', value: 'Transferência' }
    ]
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
        state.pages = action.payload.pages
        state.report = action.payload.report
        state.status = 'SUCCESS'
        state.loading = false
      })
      .addCase(getAll.pending, (state) => {
        state.loading = true
        state.status = 'PENDING'
      })
      .addCase(getAll.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload || 'Não foi possível carregar os dados'
        state.status = 'ERROR'
      })
      .addCase(get.pending, (state, action) => {
        state.status = 'PENDING'
        state.selected = action.payload
      })
      .addCase(get.rejected, (state, action) => {
        state.status = 'ERROR'
        state.error = action.payload
      })
      .addCase(get.fulfilled, (state, action) => {
        state.status = 'SUCCESS'
        state.selected = action.payload
      })
      .addCase(add.fulfilled, (state, action) => {
        state.status = 'SAVE_SUCCESS'
        state.message = 'Salvo com sucesso'
      })
      .addCase(add.rejected, (state, action) => {
        state.status = 'SAVE_ERROR'
        state.message = 'Não foi possível salvar'
        state.loading = false
      })
      .addCase(update.fulfilled, (state, action) => {
        state.status = 'SAVE_SUCCESS'
        state.message = 'Salvo com sucesso'
      })
      .addCase(update.rejected, (state, action) => {
        state.status = 'SAVE_ERROR'
        state.message = 'Não foi possível salvar'
        state.loading = false
      })
      .addCase(remove.rejected, (state, action) => {
        state.status = 'REMOVE_ERROR'
      })
      .addCase(remove.fulfilled, (state, action) => {
        state.status = 'REMOVE_SUCCESS'
      })
      .addCase(remove.pending, (state, action) => {
        state.status = 'REMOVE_PENDING'
      })
      .addCase(removeMultiples.rejected, (state, action) => {
        state.status = 'REMOVE_ERROR'
      })
      .addCase(removeMultiples.fulfilled, (state, action) => {
        state.status = 'REMOVE_SUCCESS'
      })
      .addCase(removeMultiples.pending, (state, action) => {
        state.status = 'REMOVE_PENDING'
      })

  }
})

export default invoicesSlice.reducer
