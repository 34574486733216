import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
axios.defaults.withCredentials = true;

export const getAllPlans = createAsyncThunk(
  `getAllPlans/subscriptions`,
  async () => {
    const {data} = await axios.get(`planos-assinaturas`); 
    
    return data;
  }
);

export const checkout = createAsyncThunk(
  `checkout/subscriptions`,
  async (body, { rejectWithValue }) => {
    try {
      const {data} = await axios.post(`assinaturas`, body);

      return data;
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response.data);
    }
  }
);

export const getPayments = createAsyncThunk(
  `getAllPayments/subscriptions`,
  async (params) => {
    const {data} = await axios.get(`assinaturas`, params);

    return data;
  }
);

export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState: {
    plans: [],
    payments: [],
    checkout: {},
  },
  reducers: {
    toggleModal: (state, action) => {
      state.editOpen = !state.editOpen;
    },
    toggleDelete: (state, action) => {
      state.deleteOpen = !state.deleteOpen;
    },
    clearItem: (state, payload) => {
      state.item = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPlans.fulfilled, (state, action) => {
        state.plans = action.payload;
        state.status = "SUCCESS_PLANS";
        state.loading = false;
      })
      .addCase(getAllPlans.pending, (state) => {
        state.loading = true;
        state.status = "PENDING_PLANS";
      })
      .addCase(getAllPlans.rejected, (state, action) => {
        state.loading = false;
        state.plans = [];
        state.error = action.payload || "Não foi possível carregar os dados";
        state.status = "ERROR_PLANS";
      })
      .addCase(getPayments.pending, (state, action) => {
        state.status = "PENDING_PAYMENTS";
        state.loading = true;
      })
      .addCase(getPayments.rejected, (state, action) => {
        state.status = "ERROR_PAYMENTS";
        state.loading = false;
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        state.status = "SUCCESS_PAYMENTS";
        state.payments = action.payload;
        state.loading = false;
      })
      .addCase(checkout.fulfilled, (state, action) => {
        state.status = "SUCCESS_CHECKOUT";
        state.checkout = action.payload;
        state.loading = false;
      })
      .addCase(checkout.pending, (state, action) => {
        state.status = "PENDING_CHECKOUT";
        state.loading = true;
      })
      .addCase(checkout.rejected, (state, action) => {
        state.status = "ERROR_CHECKOUT";
        state.checkout = {};
        state.message =
          action?.payload?.message || "Não foi possível realizar checkout";
        state.loading = false;
      });
  },
});

export const { clearItem } = subscriptionsSlice.actions;
export const { openModal, toggleModal, toggleDelete } =
  subscriptionsSlice.actions;
export default subscriptionsSlice.reducer;
