import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = 'proposals'

export const get = createAsyncThunk(`${endpoint}/get`, async params => {
	const response = await axios.get(`${endpoint}`, { params })
	return response.data
})

export const add = createAsyncThunk(`${endpoint}/add`, async (item, { dispatch, rejectWithValue }) => {

	try {
		const response = await axios.post(endpoint, item)
		return response.data
	} catch (e) {
		if (!e.response) {
			throw e
		}
		return rejectWithValue(e.response.data)
	}
})

export const update = createAsyncThunk(`${endpoint}/update`, async (item, { dispatch }) => {
	const response = await axios.put(`${endpoint}/${item.id}`, item)
	return response.data.data
})

export const getCloseBusinessProposal = createAsyncThunk(`${endpoint}/close`, async (item, { rejectWithValue }) => {
	try {
		const response = await axios.post(`${endpoint}/close`, item)
		return response.data
	} catch (err) {
		if (!err.response) {
			throw err
		}
		return rejectWithValue(err.response.data)
	}
});

export const uploadPdfProposal = createAsyncThunk('/uploadPdfProposal', async ({ id, file }) => {
	const formData = new FormData();
	formData.append('upload_arquivo', file);
	formData.append('_method', 'PUT');

	try {
		const response = await axios.post(`/orcamento/${id}`, formData);
		return response.data;
	} catch (error) {
		throw new Error(error.response?.data?.message || 'Erro ao fazer upload do arquivo');
	}
});

export const downloadPdfProposal = createAsyncThunk(`${endpoint}/downloadPdfProposal`, async (item) => {
	const response = await axios.get(`/orcamento/downloadpdf/${item.id}`, { responseType: 'blob' })
	const url = window.URL.createObjectURL(new Blob([response.data]));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', item.nome_arquivo);
	document.body.appendChild(link);
	link.click();
	link.parentNode.removeChild(link);
})

export const removePdfOrcamento = createAsyncThunk(`${endpoint}/deletePdfOrcamento`, async (id) => {
	await axios.delete(`/orcamento/deletepdf/${id}`)
})

export const proposalSlice = createSlice({
	name: endpoint,
	initialState: {
		data: [],
		status: 'READY',
		total: 0,
		params: {},
		closeBusiness: {},
		selected: {},
		error: ''
	},
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(get.pending, (state, action) => {
				state.status = 'PENDING'
				state.data = action.payload
			})
			.addCase(get.rejected, (state, action) => {
				state.status = 'ERROR'
				state.error = action.payload
			})
			.addCase(get.fulfilled, (state, action) => {
				state.status = 'SUCCESS'
				state.data = action.payload
			})
			.addCase(add.fulfilled, (state, action) => {
				state.status = 'SAVE_SUCCESS'
				state.message = 'Salvo com Sucesso!'
			})
			.addCase(add.rejected, (state, action) => {
				state.status = 'SAVE_ERROR'
				state.message = action.payload.message || 'Não foi possível salvar'
			})
			.addCase(update.fulfilled, (state, action) => {
				state.status = 'UPDATED_SUCCESS'
				state.message = 'Proposta atualizada para inativo com sucesso!'
				state.selected = action.payload
			})
			.addCase(update.pending, (state) => {
				state.status = 'PENDING'
				state.loading = true
			})
			.addCase(update.rejected, (state, action) => {
				state.status = 'UPDATED_ERROR'
				state.message = action.payload?.message || 'Não foi possível atualizar'
			})
			.addCase(getCloseBusinessProposal.pending, (state, action) => {
				state.status = 'PENDING'
			})
			.addCase(getCloseBusinessProposal.rejected, (state, action) => {
				state.status = 'ERROR'
				state.error = action?.payload?.message
			})
			.addCase(getCloseBusinessProposal.fulfilled, (state, action) => {
				state.status = 'SUCCESS'
				state.closeBusiness = action.payload
			})
			.addCase(uploadPdfProposal.pending, (state, action) => {
				state.status = 'UPLOAD_PENDING'
			})
			.addCase(uploadPdfProposal.rejected, (state, action) => {
				state.status = 'UPLOAD_ERROR'
				state.error = action?.payload?.message
			})
			.addCase(uploadPdfProposal.fulfilled, (state, action) => {
				state.status = 'UPLOAD_SUCCESS'
				state.message = action.payload.message
			})
			.addCase(downloadPdfProposal.fulfilled, (state, action) => {
				state.status = 'DOWNLOAD_PDF_SUCCESS'
				state.message = 'Download feito com sucesso!'
			})
			.addCase(downloadPdfProposal.rejected, (state, action) => {
				state.status = 'DOWNLOAD_PDF_ERROR'
				state.message = action.payload.message || 'Não foi possível fazer o download do arquivo!'
			})
			.addCase(removePdfOrcamento.fulfilled, (state, action) => {
				state.status = 'REMOVE_PDF_SUCCESS'
				state.message = 'Arquivo removido com sucesso!'
			})
			.addCase(removePdfOrcamento.rejected, (state, action) => {
				state.status = 'REMOVE_PDF_ERROR'
				state.message = action.payload.message || 'Não foi possível fazer o download do arquivo!'
			})
			.addCase(removePdfOrcamento.pending, (state, action) => {
				state.status = 'PDF_PENDING'
			})
	}
})

export default proposalSlice.reducer 