import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
const endpoint = 'tipo_tarefas'

export const getAll = createAsyncThunk(`${endpoint}/all`, async (params, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${endpoint}`, { params })

        return {
            data: response.data.data,
            total: response.data.data.length,
            params
        }
    } catch (error) {
        if (!error.response) {
            throw err
        }
        return rejectWithValue(error.response?.data);
    }
})

export const get = createAsyncThunk(`${endpoint}/get`, async id => {
    const response = await axios.get(`${endpoint}/${id}`, { id })
    return response.data
})

export const add = createAsyncThunk(`${endpoint}/add`, async (item, { dispatch, rejectWithValue }) => {

    try {
        const response = await axios.post(endpoint, item)

        return response.data
    } catch (e) {

        if (!e.response) {
            throw e
        }

        return rejectWithValue(e.response.data)
    }
})

export const update = createAsyncThunk(`${endpoint}/update`, async (item, { dispatch }) => {
    await axios.put(`${endpoint}/${item.id}`, item)
    return item
})

export const remove = createAsyncThunk(`${endpoint}/remove`, async (item, { dispatch }) => {
    await axios.put(`${endpoint}/${item.id}`, item)
    return item
})


export const tipoTarefaSlice = createSlice({
    name: endpoint,
    initialState: {
        data: [],
        message: '',
        status: 'READY',
        total: 0,
        params: {},
        error: ''
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getAll.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.total
                state.status = 'SUCCESS'
            })
            .addCase(getAll.pending, (state) => {
                state.loading = true
                state.data = []
                state.status = 'PENDING'
            })
            .addCase(getAll.rejected, (state, action) => {
                state.loading = false
                state.data = []
                state.error = action.payload || 'Não foi possível carregar os dados'
                state.status = 'ERROR'
            })
            .addCase(get.pending, (state, action) => {
                state.status = 'PENDING'
                state.selected = action.payload
            })
            .addCase(get.rejected, (state, action) => {
                state.status = 'ERROR'
                state.error = action.payload
            })
            .addCase(get.fulfilled, (state, action) => {
                state.status = 'SUCCESS'
                state.selected = action.payload
            })
            .addCase(add.fulfilled, (state, action) => {
                state.status = 'SAVE_SUCCESS'
                state.message = action.payload.message
            })
            .addCase(add.rejected, (state, action) => {
                state.status = 'SAVE_ERROR'
                state.message = action.payload.message || 'Não foi possível salvar'
                state.loading = false
            })
            .addCase(update.fulfilled, (state, action) => {
                state.status = 'UPDATED_SUCCESS'
                state.message = action.payload.message
            })
            .addCase(update.rejected, (state, action) => {
                state.status = 'UPDATED_ERROR'
                state.message = action.payload.message || 'Não foi possível editar'
                state.loading = false
            })
            .addCase(remove.rejected, (state, action) => {
                state.status = 'REMOVE_ERROR'
            })
            .addCase(remove.fulfilled, (state, action) => {
                state.status = 'REMOVE_SUCCESS'
            })
            .addCase(remove.pending, (state, action) => {
                state.status = 'REMOVE_PENDING'
            })
    }
})

export default tipoTarefaSlice.reducer
