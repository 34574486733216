import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const endpoint = 'business_units'

export const getAll = createAsyncThunk(`${endpoint}/all`, async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(endpoint, { params })
    return { allData: response.data.data, data: response.data.data, totalPages: response.data.total, params }
  } catch (error) {
    if (!error.response) {
      throw err
    }
    return rejectWithValue(error.response?.data);
  }
})

export const get = createAsyncThunk(`${endpoint}/get`, async id => {
  const response = await axios.get(`${endpoint}/${id}`, { id })
  return response.data
})

export const add = createAsyncThunk(`${endpoint}/add`, async (item, { dispatch, rejectWithValue }) => {
  try {
    await axios.post(endpoint, item)
    // await dispatch(getAll())
  } catch (e) {
    if (!e.response) {
      throw e
    }

    return rejectWithValue(e.response.data)
  }
  return item
})

export const update = createAsyncThunk(`${endpoint}/update`, async (item, { dispatch }) => {
  await axios.put(`${endpoint}/${item.id}`, item)
  await dispatch(get(item.id))
  return item
})

export const updateLp = createAsyncThunk(`${endpoint}/update-landingpage`, async (item, { dispatch }) => {
  await axios.put(`${endpoint}/update-landingpage`, item)
  return item
})

export const updateImg = createAsyncThunk(`${endpoint}/updateImg`, async (item) => {
  item._method = 'PUT'
  await axios.postForm(`${endpoint}/${item.id}`, item)
  return item
})

export const remove = createAsyncThunk(`${endpoint}/remove`, async (item, { dispatch }) => {
  await axios.delete(`${endpoint}/${item.id}`)
  await dispatch(getAll())
  return item
})

export const updateLoggedOrganization = createAsyncThunk(`organization/update`, async (data, { dispatch }) => {
  const response = await axios.put(`organization/update`, data)
  return response.data
})

export const removeMultiples = createAsyncThunk(`${endpoint}/removeMultiples`, async (ids, { dispatch }) => {
  await axios.delete(`${endpoint}/delete-multiples`, { data: { ids } })
})

export const business_unitsSlice = createSlice({
  name: endpoint,
  initialState: {
    data: [],
    status: 'READY',
    total: 1,
    params: {},
    selected: null,
    error: ''
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.status = 'SUCCESS'
      })
      .addCase(getAll.pending, (state) => {
        state.loading = true
        state.status = 'PENDING'
      })
      .addCase(getAll.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload || 'Não foi possível carregar os dados'
        state.status = 'ERROR'
      })
      .addCase(get.fulfilled, (state, action) => {
        state.status = 'SUCCESS'
        state.selected = action.payload
      })
      .addCase(add.fulfilled, (state, action) => {
        state.selected = action.payload
        state.status = 'SAVE_SUCCESS'
      })
      .addCase(add.rejected, (state, action) => {
        state.status = 'SAVE_ERROR'
        state.error = action.payload || 'Não foi possível salvar'
        state.loading = false
      })
      .addCase(update.fulfilled, (state) => {
        state.status = 'UPDATED_SUCCESS'
      })
      .addCase(update.rejected, (state, action) => {
        state.error = action.error
        state.status = 'UPDATED_ERROR'
      })
      .addCase(updateImg.fulfilled, (state) => {
        state.status = 'UPDATED_IMG_SUCCESS'
      })
      .addCase(updateImg.rejected, (state, action) => {
        state.error = action.error
        state.status = 'UPDATED_IMG_ERROR'
      })
      .addCase(updateLp.fulfilled, (state) => {
        state.status = 'LP_UPDATED_SUCCESS'
      })
      .addCase(updateLp.rejected, (state, action) => {
        state.error = action.error
        state.status = 'LP_UPDATED_ERROR'
      })
      .addCase(remove.fulfilled, (state, action) => {
        state.status = 'REMOVED'
        state.selected = action.payload
      })
      .addCase(remove.pending, (state, action) => {
        state.status = 'ERROR'
      })
      .addCase(removeMultiples.rejected, (state, action) => {
        state.status = 'REMOVE_ERROR'
      })
      .addCase(removeMultiples.fulfilled, (state, action) => {
        state.status = 'REMOVE_SUCCESS'
      })
      .addCase(removeMultiples.pending, (state, action) => {
        state.status = 'REMOVE_PENDING'
      })
      .addCase(updateLoggedOrganization.fulfilled, (state) => {
        state.status = 'SUCCESS'
      })
      .addCase(updateLoggedOrganization.rejected, (state, action) => {
        state.error = action.message || action.msg || 'Não foi possível atualizar'
        state.status = 'ERROR'
      })
      .addCase(updateLoggedOrganization.pending, (state, action) => {
        state.status = 'PENDING'
      })

  }
})

export default business_unitsSlice.reducer
