import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { useParams } from "react-router-dom";

/**
 * Retrieves tasks for a specific budget.
 *
 * @param {string} params.id - The ID of the budget.
 * @returns {Promise<Array>} - Returns a promise that resolves to an array of tasks for the budget.
 */

export const getAll = createAsyncThunk(`tarefas/all`, async (params, { rejectWithValue }) => {
  try {
    const endpoint = `orcamento/${params.id}/tarefas`
    const response = await axios.get(endpoint, { params })
    return response.data
  } catch (error) {
    if (!error.response) {
      throw err
    }
    return rejectWithValue(error.response?.data);
  }
})

export const get = createAsyncThunk(`tarefas/get`, async ({ orcamentoId, tarefaId }) => {
  const endpoint = `orcamento/${orcamentoId}/tarefas/${tarefaId}`
  const response = await axios.get(endpoint)
  return response.data.data
})

export const blockedDates = createAsyncThunk(`tarefas/blockedDates`, async date => {
  const response = await axios.get(`/calendar-blocks/check/${date}`)
  return response.data
})

export const add = createAsyncThunk(`tarefas/add`, async (body) => {
  const endpoint = `orcamento/${body.orcamentoId}/tarefas`;
  const tarefa = {
    data_historico: body.data_historico,
    descricao: body.descricao,
    horario: body.horario,
    tipo: body.tipo,
    completed: body.completed,
    concluida: body.concluida,
    cancelado: body.cancelado,
    fk_etapa: body.fk_etapa,
    email_assunto: body.email_assunto,
    email_corpo: body.email_corpo
  }
  const response = await axios.post(endpoint, tarefa)
  return response.data.data
})

export const update = createAsyncThunk(`tarefas/update`, async (item) => {
  const endpoint = `orcamento/${item.orcamentoId}/tarefas/${item.tarefaId}`
  const response = await axios.put(endpoint, {
    data_historico: item.data_historico,
    descricao: item.descricao,
    horario: item.horario,
    tipo: item.tipo,
    completed: item.completed,
    concluida: item.concluida,
    cancelado: item.cancelado,
    fk_etapa: item.fk_etapa
  })
  return response.data.data
})

export const updateData = createAsyncThunk(`tarefas/updateData`, async (item) => {
  const endpoint = `orcamento/${item.orcamentoId}/tarefas/${item.tarefaId}`
  const response = await axios.put(endpoint, {
    data_historico: item.data_historico,
    descricao: item.descricao,
    horario: item.horario,
    tipo: item.tipo,
    completed: item.completed
  })
  return response.data.data
})

export const remove = createAsyncThunk(`tarefas/remove`, async ({ orcamentoId, tarefaId }) => {
  const endpoint = `orcamento/${orcamentoId}/tarefas/${tarefaId}`
  await axios.delete(endpoint)
})

export const tarefaOrcamentoSlice = createSlice({
  name: 'tarefaOrcamento',
  initialState: {
    data: [],
    message: '',
    status: 'READY',
    total: 0,
    params: {},
    date_block: '',
    error: '',
    editOpen: false,
    deleteOpen: false,
    loading: false,
    selected: {}
  },
  reducers: {
    handleSelectTask: (state, action) => {
      state.selected = action.payload
    },
    clearSelected: (state, payload) => {
      state.selected = {}
    },
    toggleModal: (state, action) => {
      state.editOpen = !state.editOpen
    },
    toggleDelete: (state, action) => {
      state.deleteOpen = !state.deleteOpen
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.status = 'SUCCESS'
        state.loading = false
      })
      .addCase(getAll.pending, (state) => {
        state.loading = true
        state.data = []
        state.status = 'PENDING'
      })
      .addCase(getAll.rejected, (state, action) => {
        state.loading = false
        state.data = []
        state.error = action.payload || 'Não foi possível carregar os dados'
        state.status = 'ERROR'
      })
      .addCase(get.pending, (state, action) => {
        state.status = 'PENDING'
        state.selected = action.payload
      })
      .addCase(get.rejected, (state, action) => {
        state.status = 'ERROR'
        state.error = action.payload
      })
      .addCase(get.fulfilled, (state, action) => {
        state.status = 'SUCCESS'
        state.selected = action.payload
      })
      .addCase(add.fulfilled, (state, action) => {
        state.status = 'SAVE_SUCCESS'
        state.message = 'Salvo com Sucesso!'
      })
      .addCase(add.rejected, (state, action) => {
        state.status = 'SAVE_ERROR'
        state.message = 'Não foi possível salvar'
        state.loading = false
      })
      .addCase(update.fulfilled, (state, action) => {
        state.status = 'UPDATED_SUCCESS'
        state.message = 'Atualizado com Sucesso!'
      })
      .addCase(update.rejected, (state, action) => {
        state.status = 'UPDATED_ERROR'
        state.message = 'Não foi possível atualizar'
        state.loading = false
      })
      .addCase(updateData.fulfilled, (state, action) => {
        state.status = 'UPDATED_SUCCESS'
        state.message = 'Adiado com Sucesso!'
      })
      .addCase(updateData.rejected, (state, action) => {
        state.status = 'UPDATED_ERROR'
        state.message = 'Não foi possível adiar'
        state.loading = false
      })
      .addCase(remove.rejected, (state, action) => {
        state.status = 'REMOVE_ERROR'
      })
      .addCase(remove.fulfilled, (state, action) => {
        state.status = 'REMOVE_SUCCESS'
        state.message = 'Removido com Sucesso!'
      })
      .addCase(remove.pending, (state, action) => {
        state.status = 'REMOVE_PENDING'
        state.message = 'Não foi possível remover'
      })
      .addCase(blockedDates.fulfilled, (state, action) => {
        state.status = 'SUCCESS'
        state.date_block = action.payload
      })
      .addCase(blockedDates.pending, (state, action) => {
        state.status = 'PENDING'
      })
      .addCase(blockedDates.rejected, (state, action) => {
        state.status = 'ERROR'
        state.error = action.payload
      })
  }
})

export const { clearSelected } = tarefaOrcamentoSlice.actions
export const { openModal, toggleModal, toggleDelete } = tarefaOrcamentoSlice.actions
export default tarefaOrcamentoSlice.reducer